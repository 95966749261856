<template>
  <div>
    <!-- trabajadores -->
    <v-row>
      <v-col>
        <s-crud
          :single-row="false"
          :add="false"
          @add="addWorker()"
          noEdit
          saveList
          addNoFunction
          searchInput
          :save="conectadoBiometric"
          @save="AsignarReloj()"
          @rowSelected="selectedWorker($event)"
          ref="gridWorker"
          title="Trabajadores"
          :config="configWorker"
          :filter="filterWorker"
          @doubleClick="assignWorker($event)"
        >
          <template v-slot:filter>
            <v-container>
              <v-row justify="center"
                ><v-col lg="6">
                  <s-select-area
                    v-model="filterWorker.AreID"
                    label="Área" /></v-col
              ></v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <!-- ------------------------- -->
      <v-col>
        <s-crud
          :single-row="false"
          :add="false"
          @add="addBiometricWorker()"
          @rowSelected="selectedBiometricWorker($event)"
          searchInput
          title="Trabajadores asignados al equipo biométrico"
          :config="configBiometricWorker"
          :filter="filterBiometricWorker"
          ref="gridBiometricWorker"
          :deleteNoFunction="true"
          :remove="conectadoBiometric && !useConection"
          @delete="deleteExecuted(itemsBiometricWorker)"
        >
          <template v-slot:filter>
            <v-container>
              <v-row>
                <v-col
                  ><s-select-generic
                    itemtext="BieTxtDisplay"
                    itemvalue="BieID"
                    id="BieID"
                    v-model="filterBiometricWorker.BieID"
                    :config="configSelectBiometric"
                    @change="
                      selectedBiometric($event, filterBiometricWorker.BieID)
                    "
                    :disabled="conectadoBiometric || useConection"
                  ></s-select-generic
                ></v-col>
                <v-col style="width: 100%">
                  <s-select-generic
                    itemtext="BieIP"
                    itemvalue="BieID"
                    id="BieIP"
                    v-model="filterBiometricWorker.BieID"
                    :config="configSelectBiometric"
                    disabled="disabled"
                  ></s-select-generic>
                </v-col>
                <v-col>
                  <v-btn
                    v-show="!conectadoBiometric && !useConection"
                    :disabled="useConection"
                    style="
                      height: 25px;
                      background-color: #1fbc07 !important;
                      color: #fff !important;
                    "
                    @click="fn_connectFingerPrint"
                    >Conectar</v-btn
                  >

                  <v-btn
                    v-show="conectadoBiometric && !useConection"
                    :disabled="useConection"
                    style="
                      height: 25px;
                      background-color: #ff0000 !important;
                      color: #fff !important;
                    "
                    @click="fn_disconnectFingerPrint"
                    >Desconectar</v-btn
                  >

                  <v-btn v-show="useConection" style="height: 25px"
                    >Conectando...</v-btn
                  >
                </v-col>
              </v-row>
              <!-- areas -->
              <!-- <v-row>
                <v-col>
                  <s-select-area
                    v-model="filterBiometricWorker.AreID"
                    label="Área"
                  />
                </v-col>
              </v-row> -->
            </v-container>
          </template>
          <template v-slot:AsgAction="{ row }">
            <v-btn
              :disabled="!conectadoBiometric || useConection"
              x-small
              style="margin-right: 10px"
              color="primary"
              @click="AsgFingerPrint(row)"
            >
              <i class="fas fa-sync-alt"></i>
            </v-btn>
            <v-btn
              :disabled="!conectadoBiometric || useConection"
              x-small
              style="margin-right: 10px"
              :style="{
                color: 'white',
              }"
              color="green"
              @click="ShowFingers(row)"
            >
              <v-icon> mdi-fingerprint </v-icon>
            </v-btn>
          </template>
        </s-crud>
      </v-col>
    </v-row>
    <!-- ---------------------------------------------------->
    <!-- Huellas -->
    <v-dialog v-model="fingerPrint" max-width="800">
      <v-card :loading="!loadingFingerPrint">
        <s-toolbar
          :label="'HUELLAS EN RELOJ DE ' + WorkerSelected.BiwPersonName"
          color="#BAB6B5"
          :close="loadingFingerPrint"
          @close="closefingerPrint()"
          style="color: white"
        />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card dense>
                <v-card-title class="text-h7" style="font-weight: bold">
                  <v-row justify="center">
                    <v-col cols="6" class="d-flex justify-center">
                      <span>IZQUIERDA</span>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-center">
                      <span>DERECHA</span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row justify="center">
                    <v-col cols="12" class="d-flex justify-center">
                      <!-- Imagen de las manos con contenedor relative -->
                      <div style="position: relative; display: inline-block">
                        <v-img
                          lazy-src="@/assets/hands1.png"
                          max-height="400"
                          max-width="700"
                          src="@/assets/hands1.png"
                          class="d-flex justify-center align-center"
                        />
                        <div
                          v-for="(finger, index) in fingers"
                          :key="index"
                          class="finger"
                          :style="{
                            position: 'absolute',
                            top: finger.position.top,
                            left: finger.position.left,
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            color: 'black',
                            transition: 'background-color 0.3s',
                          }"
                        >
                          <v-btn
                            :style="{
                              backgroundColor:
                                selectedFinger === index
                                  ? 'orange'
                                  : finger.isActive
                                  ? '#53f773'
                                  : finger.isInBiometric && !finger.isInDB
                                  ? 'orange'
                                  : 'transparent',
                              width: '10px',
                              height: '30px',
                              minWidth: '10px',
                              borderRadius: '50%',
                            }"
                            @mouseover="highlightFinger(index)"
                            @mouseleave="resetHighlight"
                            @click="selectFinger(index)"
                            :disabled="!loadingFingerPrint"
                          >
                            <!-- Número de la huella -->
                            {{ index + 1 }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- Leyenda de colores -->
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12" class="d-flex justify-center">
                      <v-chip color="#53f773" class="mr-2"
                        >Huella activa</v-chip
                      >
                      <v-chip color="orange" class="mr-2"
                        >Huella eliminada en el sistema</v-chip
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fingerprintScan" max-width="300px" persistent>
      <v-card>
        <v-card-title
          class="d-flex justify-center"
          style="background-color: #fadf9b"
        >
          <span class="headline">{{
            fingerprintScan ? "Captura Huella" : "Esperando...."
          }}</span>
        </v-card-title>
        <v-card-text style="margin-top: 5px">
          <div class="d-flex justify-center">
            <!-- Icono de huella con animación de escaneo -->
            <div class="fingerprint-icon-container">
              <v-img
                src="@/assets/FingrPrint.png"
                alt="Fingerprint Icon"
                class="fingerprint-icon"
                max-height="150"
                max-width="150"
                v-if="scanning"
                :style="{
                  backgroundColor: fingerprintScan
                    ? '#ffcec7'
                    : captured
                    ? '#d5fb9f'
                    : 'transparent',
                }"
              >
                <v-progress-linear
                  v-if="scanning"
                  indeterminate
                  color="red"
                  class="scanning-line"
                  :style="{ height: '10px' }"
                />
              </v-img>
              <v-progress-linear
                v-if="scanning"
                indeterminate
                color="red"
                class="scanning-line"
                :style="{ height: '10px' }"
              />
              <!-- Check verde cuando la huella se haya capturado correctamente -->
              <v-icon
                v-if="captured"
                color="green"
                class="capture-success"
                style="font-size: 50px"
              >
                mdi-check-circle
              </v-icon>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="" @click="CloseFootprint" :disabled="captured">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ----------------------------------- -->
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import WorkerService from "../../../services/HumanResource/WorkerService";
import _sHelper from "@/services/HelperService";
import _sGenParam from "@/services/General/ParameterService";
import _sBiometricEquipmentService from "../../../services/HumanResource/BiometricEquipmentService";
import _sBiometricWorkerService from "../../../services/HumanResource/BiometricWorkerService";
import Loader from "@/components/Layout/Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      conectadoBiometric: false,
      useConection: false,

      isLoading: false,
      errorUsers: [],
      loadingFingerPrint: false,
      fingerprintScan: false,
      fingerPrint: false,
      captured: false,
      scanning: false,
      fingers: [
        {
          id: 0,
          position: { top: "19%", left: "14%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 1,
          position: { top: "10%", left: "19%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 2,
          position: { top: "8%", left: "24%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 3,
          position: { top: "10%", left: "29.5%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 4,
          position: { top: "42%", left: "42%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 5,
          position: { top: "42%", left: "54%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 6,
          position: { top: "10%", left: "66%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 7,
          position: { top: "8%", left: "71%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 8,
          position: { top: "10%", left: "77%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
        {
          id: 9,
          position: { top: "20%", left: "82%" },
          style: {},
          isActive: false,
          isInBiometric: false,
          isInDB: false,
        },
      ],
      selectedFinger: null,
      dataIP: "",
      dataPort: 4370,
      dataType: 1,

      Name: "",
      Password: "",
      Privilege: 0,
      Enabled: true,

      data: {
        MachineNumber: 1,
        EnrollNumber: "0",

        FingerIndex: null,
        Flag: 0,
        TemplateData: "",
      },
      idfingersDB: [],
      BiometricsSelected: [],
      WorkersSelected: [],
      WorkerSelected: 0,
      WorkerBioSelected: 0,
      itemsWorker: [],
      itemsBiometric: [],
      itemsBiometricWorker: [],
      selectionBefore: [],
      filterBiometric: {},
      biometricId: 0,
      filterWorker: {
        AreID: 0,
      },
      filterBiometricWorker: {
        BieID: 0,
      },
      configWorker: {
        service: WorkerService,
        model: {
          WkrID: "ID",
        },
        headers: [
          { text: "Dni", value: "PrsDocumentNumber", sortable: true },
          { text: "WkrID", value: "WkrID", sortable: true },
          { text: "Trabajador", value: "WkrName", sortable: true },
          { text: "Area", value: "AreName", sortable: true },
        ],
        listWorkersHeaders: [
          { text: "Trabajador", value: "WkrName", sortable: true },
          { text: "Area", value: "AreName", sortable: true },
        ],
      },
      configBiometricWorker: {
        service: _sBiometricWorkerService,
        model: {
          BiwID: "ID",
          AreID: "int",
          AsgAction: "",
        },
        headers: [
          { text: "Dni", value: "PrsDocumentNumber", sortable: true },
          { text: "ID", value: "WkrID", sortable: false },
          { text: "Trabajador", value: "BiwPersonName" },
          {
            text: "Txt de la Pantalla",
            value: "BiwBiometricDisplay",
          },
          {
            text: "Acciones",
            value: "AsgAction",
            sortable: false,
            align: "center",
          },
        ],
      },
      configSelectBiometric: {
        url: _sBiometricEquipmentService.list(),
        params: {},
        title: "Biométrico",
      },
    };
  },
  methods: {
    async deleteExecuted(itemsBiometricWorker) {
      this.isLoading = true;
      this.useConection = true;
      this.errorUsers = [];
      for (var event of itemsBiometricWorker) {
        event.SecStatus = 0;
        await WorkerService.deleteUserFingerPrint(
          event.type,
          1,
          event.WkrID,
          this.$fun.getUserID()
        )
          .then(() => {
            _sBiometricWorkerService
              .save(event, this.$fun.getUserID())
              .then((r) => {
                //this.$fun.alert("Eliminado correctamente", "success");
              });
          })
          .catch((e) => {
            event.SecStatus = 1;
            this.errorUsers.push(event);
          })
          .finally(() => {
            this.$refs.gridBiometricWorker.refresh();
            this.useConection = false;
            this.isLoading = false;
          });
      }
      if (this.errorUsers.length > 0) {
        this.$fun.alertFull(
          "Ocurrio un error al eliminar las huellas: " +
            this.errorUsers.map((x) => x.WkrName).join(",<br>"),
          "warning"
        );
      } else {
        this.$fun.alert("Proceso completado correctamente", "success");
      }
    },
    fn_connectFingerPrint() {
      this.useConection = true;
      WorkerService.connectFingerPrint(
        this.dataIP,
        this.dataPort,
        this.dataType,
        this.$fun.getUserID()
      )
        .then((response) => {
          if (response.data.success) {
            this.conectadoBiometric = true;
            this.$fun.alert("Conectado correctamente", "success");
            this.useConection = false;
          } else {
            this.useConection = false;
            this.conectadoBiometric = false;
            this.$fun.alert("Ocurrio un error al conectar", "warning");
          }
        })
        .catch((error) => {
          this.useConection = false;
          this.conectadoBiometric = false;
        });
    },
    fn_disconnectFingerPrint() {
      this.useConection = true;
      WorkerService.disconnectFingerPrint(
        this.dataIP,
        this.dataPort,
        this.dataType,
        this.$fun.getUserID()
      )
        .then((response) => {
          if (response.data.success) {
            this.conectadoBiometric = false;
            this.$fun.alert("Desconectado correctamente", "success");
          } else {
            this.conectadoBiometric = true;
            this.$fun.alert("Ocurrio un error al desconectar", "warning");
          }
          this.useConection = false;
          this.conectadoBiometric = false;
        })
        .catch((error) => {
          this.conectadoBiometric = true;
          this.$fun.alert(error, "warning");
          this.useConection = false;
        });
    },
    addWorker() {
      this.selectionBefore.forEach((element) => {
        if (this.itemsWorker.find((x) => x == element) == null)
          this.itemsWorker.push(element);
      });
    },
    addBiometricWorker() {
      this.selectionBefore.forEach((element) => {
        if (this.itemsBiometricWorker.find((x) => x == element) == null)
          this.itemsBiometricWorker.push(element);
      });
    },
    selectedWorker(items) {
      this.itemsWorker = items;
    },
    selectedBiometricWorker(items) {
      this.itemsBiometricWorker = items;
    },
    selectedBiometric(items, newBieID) {
      this.itemsBiometric = items;

      const selectedBiometric = this.itemsBiometric.find(
        (item) => item.BieID === newBieID
      );

      if (selectedBiometric && selectedBiometric.BieIP) {
        this.dataIP = selectedBiometric.BieIP;
        //transformar a entero
        this.dataPort = parseInt(selectedBiometric.BiePortIP);
        this.dataType = selectedBiometric.BieModel;
      } else {
        this.dataIP = this.itemsBiometric[0].BieIP;
        this.dataPort = parseInt(this.itemsBiometric[0].BiePortIP);
        this.dataType = this.itemsBiometric[0].BieModel;
      }
    },
    async AsgFingerPrint(row) {
      try {
        this.isLoading = true;
        this.data.EnrollNumber = String(row.WkrID);
        this.useConection = true;

        const r = await _sBiometricWorkerService.listFingerPrintUser(
          row.NtpID,
          this.$fun.getUserID()
        );

        if (r.status == 200) {
          // Esperar a que todos los registros de huellas se procesen
          for (const fingerprint of r.data) {
            this.data.FingerIndex = fingerprint.WfpTypeFinger;
            this.data.TemplateData = fingerprint.NtpValue;
            this.data.type = this.dataType;

            const fingerPrintResponse = await WorkerService.addFingerPrint(
              this.data,
              this.$fun.getUserID()
            ).catch((error) => {
              this.$fun.alert(
                "Ocurrio un error al procesar la huella " + row.WkrName,
                "warning"
              );
            });
          }

          this.$fun.alert(
            `${
              r.data.length == 1 ? "Huella" : "Huellas"
            } sincronizadas correctamente`,
            "success"
          );

          this.useConection = false;
        } else {
          this.$fun.alert(
            "Ocurrio un error al procesar las huellas",
            "warning"
          );
          this.useConection = false;
        }
      } catch (error) {
        this.$fun.alert(error, "error");
        this.useConection = false;
      }
      this.isLoading = false;
    },
    async AsignarReloj() {
      this.isLoading = true; // Activar loading
      this.useConection = true;
      const usersRegister = this.itemsWorker.map((x) => x);
      const biometric_Id = this.filterBiometricWorker.BieID;
      this.errorUsers = [];
      try {
        for (const user of usersRegister) {
          const r = await _sBiometricWorkerService.listFingerPrintUser(
            user.NtpID,
            this.$fun.getUserID()
          );
          if (r.data.length > 0) {
            this.data.EnrollNumber = String(user.WkrID);
            this.Name = user.WkrName;
            const response = await WorkerService.addUserFingerPrint(
              this.dataType,
              this.data.MachineNumber,
              this.data.EnrollNumber,
              this.Name,
              this.Password,
              this.Privilege,
              this.Enabled,
              this.$fun.getUserID()
            );
            if (response.data.success) {
              if (biometric_Id != 0) {
                await _sBiometricWorkerService.addBiometricWorker(
                  user.WkrID,
                  biometric_Id,
                  this.$fun.getUserID()
                );
              }

              if (r.status == 200) {
                for (const fingerprint of r.data) {
                  this.data.type = this.dataType;
                  this.data.FingerIndex = fingerprint.WfpTypeFinger;
                  this.data.TemplateData = fingerprint.NtpValue;
                  await WorkerService.addFingerPrint(
                    this.data,
                    this.$fun.getUserID()
                  ).catch((error) => {
                    this.$fun.alert(
                      "Ocurrio un error al procesar la huella " + this.Name,
                      "warning"
                    );
                  });
                }
              }
            } else {
              this.$fun.alert(
                "Ocurrio un error al procesar el usuario " + this.Name,
                "warning"
              );
              break;
            }
          } else {
            this.errorUsers.push(user);
          }
        }

        if (this.errorUsers.length > 0) {
          this.$fun.alertFull(
            "Los siguientes trabajadores no presentan huella: " +
              this.errorUsers.map((x) => x.WkrName).join(",<br>"),
            "warning"
          );
        } else {
          this.$fun.alert("Proceso completado correctamente", "success");
        }
      } catch (error) {
        this.$fun.alert("Ocurrio un error", error);
      } finally {
        this.isLoading = false; // Desactivar loading
        this.useConection = false;
        this.$refs.gridBiometricWorker.refresh();
        this.$refs.gridWorker.refresh();
      }
    },
    assignWorker(worker) {
      if (this.itemsWorker.find((x) => x == worker) == null)
        this.itemsWorker.push(worker);
    },
    highlightFinger(index) {
      this.selectedFinger = index;
    },
    resetHighlight() {
      this.selectedFinger = null;
    },
    selectFinger(index) {
      this.selectedFinger = index;
      //abrir modal para preguntar si desea registrar la huella
      if (this.fingers[index].isInBiometric) {
        this.$fun.alert("Eliminar huella?", "question").then((r) => {
          if (r.isConfirmed) {
            this.loadingFingerPrint = false;
            WorkerService.deleteFingerPrint(
              this.dataType,
              this.data.MachineNumber,
              this.WorkerSelected.WkrID,
              index,
              this.$fun.getUserID()
            )
              .then((response) => {
                if (response.data.success) {
                  this.$fun.alert("Huella eliminada correctamente", "success");
                  this.fingers[index].isInBiometric = false;
                  this.fingers[index].isActive = false;
                } else {
                  this.$fun.alert(
                    "Ocurrio un error al registrar la huella",
                    "warning"
                  );
                }
              })
              .catch((error) => {
                this.$fun.alert(error, "warning");
              })
              .finally(() => {
                this.loadingFingerPrint = true;
              });
          } else {
            this.selectedFinger = null;
          }
        });
      } else {
        this.$fun.alert("Registrar nueva huella?", "question").then((r) => {
          if (r.isConfirmed) {
            this.fingerprintScan = true;
            this.scanning = true;
            //registrar huella
            WorkerService.readFingerPrint(
              this.dataType,
              this.data.MachineNumber,
              this.WorkerSelected.WkrID,
              index,
              this.$fun.getUserID()
            )
              .then((response) => {
                if (response.data.success) {
                  this.$fun.alert("Huella registrada correctamente", "success");
                  this.fingers[index].isActive = true;
                  WorkerService.getFingerPrint(
                    this.dataType,
                    this.data.MachineNumber,
                    this.WorkerSelected.WkrID,
                    index,
                    this.$fun.getUserID()
                  )
                    .then((response) => {
                      this.WorkerSelected.WfpTypeFinger = index;
                      WorkerService.saveFingerPrint(
                        this.WorkerSelected,
                        response.data.fingerPrint,
                        this.$fun.getUserID()
                      )
                        .then((response) => {
                          this.$fun.alert("guardado en BD", "success");
                        })
                        .catch((error) => {
                          this.$fun.alert(error, "warning");
                        });
                    })
                    .catch((error) => {
                      this.$fun.alert(error, "warning");
                    });
                } else {
                  this.$fun.alert(
                    "Ocurrio un error al registrar la huella",
                    "warning"
                  );
                }
              })
              .catch((error) => {
                this.$fun.alert(error, "warning");
              })
              .finally(() => {
                this.fingerprintScan = false;
              });
          } else {
            this.selectedFinger = null;
          }
        });
      }
    },
    CloseFootprint() {
      this.fingerprintScan = false;
    },
    async ShowFingers(row) {
      this.fingerPrint = true;
      this.WorkerSelected = row;
      this.fingers.forEach((element) => {
        element.isActive = false;
        element.isInBiometric = false;
        element.isInDB = false;
      });
      // Obtener huellas de la base de datos
      const dbResponse = await _sBiometricWorkerService.listFingerPrintUser(
        this.WorkerSelected.NtpID,
        this.$fun.getUserID()
      );
      if (dbResponse.status == 200) {
        //sacar de data los WfpTypeFinger
        this.idfingersDB = dbResponse.data.map((x) => x.WfpTypeFinger);
        // Marcar huellas que están en la base de datos
        this.fingers.forEach((finger) => {
          finger.isInDB = this.idfingersDB.includes(finger.id);
        });
      }

      try {
        const response = await WorkerService.listFingerPrintUserInBiometric(
          this.WorkerSelected.WkrID,
          this.dataType,
          this.$fun.getUserID()
        );

        if (response.data.success) {
          response.data.idfingers.forEach((id) => {
            this.fingers[id].isInBiometric = true;
            if (this.fingers[id].isInBiometric && this.fingers[id].isInDB) {
              this.fingers[id].isActive = true;
            }
          });
        }
      } catch (error) {
        this.$fun.alert(error, "warning");
      } finally {
        this.loadingFingerPrint = true;
      }
    },
    closefingerPrint() {
      this.fingerPrint = false;
      this.loadingFingerPrint = false;
      this.fingers.forEach((element) => {
        element.isActive = false;
      });
    },
  },
  mounted() {
    _sBiometricEquipmentService
      .isConnected(this.$fun.getUserID())
      .then((response) => {
        if (response.data) {
          //cambiar el filtro de biometrico
          this.filterBiometricWorker.BieID = response.data.BieID;
          //selectedBiometric
          this.selectedBiometric([response.data], response.data.BieID);
          WorkerService.reconectFingerPrint(
            response.data.BieIP,
            response.data.BiePortIP,
            response.data.BieModel,
            this.$fun.getUserID()
          )
            .then((response) => {
              if (response.data.success) {
                this.conectadoBiometric = true;
              } else {
                this.conectadoBiometric = false;
              }
              this.$refs.gridBiometricWorker.refresh();
            })
            .catch((error) => {
              this.$fun.alert(error, "warning");
            });
        }
      })
      .catch((error) => {});
  },
};
</script>

<style scoped>
</style>