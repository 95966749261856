import Service from "../Service";
const resource = "HmnBiometricWorker/";

export default {
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  async save(adm, requestID) {
    return Service.post(resource + "save", adm, {
      params: { requestID: requestID },
    });
  },
  //Agregar trabajadores a los biometricos
  addBiometricWorker(wkr, Bie, requestID) {
    var postData = {
      worker: JSON.stringify(wkr),
      biometric: Bie,
    };
    return Service.post(resource + "addBiometricWorker", postData, {
      params: { requestID: requestID },
    });
  },
  listWorkersByBiometric(BieID, requestID) {
    return Service.get(resource + "listWorkersByBiometric", {
      params: {
        BieID: BieID,
        requestID: requestID,
      },
    });
  },
  removeWorkerOfBiometric(obj, requestID) {
    return Service.post(resource + "removeWorkerOfBiometric", obj, {
      params: {
        requestID: requestID,
      },
    });
  },

  listFingerPrintUser(NtpID, requestID) {
    return Service.post(
      resource + "listFingerPrintUser",
      {},
      {
        params: { NtpID: NtpID, requestID: requestID },
      }
    );
  },
};
